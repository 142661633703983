<template>
  <el-card style="overflow: auto;height: 99%" >
    <div v-if="TableData.length" ref="Table">
        <div class="order_card"  v-for="item in TableData">
          <el-image v-if="item.order_status===2" style="position: absolute;left:0;top:0;width: 5.5rem" :src="require('@/assets/order-status-underway.png')" />
          <el-image v-else style="position: absolute;left:0;top:0;width: 5.5rem" :src="require('@/assets/order-status-finish.png')" />
          <div class="financial_management" :class="{'grey': item.order_status===4}">
            <span :class="{'grey': item.order_status===4}">{{item.product_name}}</span>
            <span>订单号：{{item.order_number}}</span>
            <span>贷款额度：¥{{item.loan_money || "0.00"}}</span>
            <span>贷款周期：{{item.loan_cycle || 0}}天</span>
            <span>贷款利率：{{item.loan_rate || 0}}%</span>
            <span>已用：¥{{item.used_loan_money || "0.00"}} | 余额：¥{{item.no_loan_money || "0.00"}}</span>
          </div>
        </div>
    </div>
    <div v-else class="not_data">
      暂无数据
    </div>
  </el-card>
</template>
<script>
export default {
  name: "PersonalCenter",
  data(){
    return{
      TableData:[]
    }
  },
  mounted() {
    this.search()
  },
  methods:{
    search(){
      this.$api.post('v1/api/makafin/loan_order/tabulation',{},res=>{
       this.TableData=res.list
        this.$nextTick(()=>{
          if(this.TableData.length>=3){
            this.$refs.Table.classList.add('center')
          }else{
            this.$refs.Table.classList.remove('center')
          }
        })

      })
    },
  },
}
</script>
<style scoped>
.grey{
  color:#999!important;
}
.center{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.financial_management{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.financial_management >span:nth-child(1){
  font-size: 2rem;
  color:#FFBA56;
  margin: 2rem 0;
  line-height: 2rem;
}
.financial_management >span{
  font-size: 1.125rem;
  margin-bottom: 1.5rem;
}
.order_card{
  width: 30rem;
  height: 22.75rem;
  position: relative;
  display: inline-block;
  margin-right: 1.25rem;
  margin-bottom: 1.25rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.88rem #77777740;
}
.not_data{
  width:100%;
  height:100%;
  display: flex;
  justify-content:center;
  align-items:center;
  font-size: 1.125rem;
  padding-top: 4rem;
  color:#999999;
}
</style>